import * as rt from 'runtypes';

import cache from '../cache';
import log from '../utils/log';
import { getAidDomain } from '../utils/url';
import { fetcher } from '../utils/fetcher';
import ProblemJsonError from '../exceptions/ProblemJsonError';

export const CACHE_KEY = 'haveTriedThirdPartyLoginCheck';
export const CACHE_TTL = 60 * 60 * 1000; // One hour

export default async function thirdPartyCookie() {
  if (await hasAlreadyAttemptedThirdPartyLogin()) {
    return false;
  }
  await flagThirdPartyLoginAttempt();

  try {
    return await getIsLoggedIn();
  } catch (error) {
    log.warn('Unhandled error fetching data from session from aid.no', {
      error,
      extendedDebug: {
        type: typeof error,
        isError: error instanceof Error,
      },
    });
    return false;
  }
}

async function getIsLoggedIn(): Promise<boolean> {
  try {
    /**
     * We don't care about the response as long as it is successful.
     * fetcher throws for any non 2xx response.
     */
    await fetcher(`https://${getAidDomain()}/api/aid/users/self`, {
      mode: 'cors',
      credentials: 'include',
      headers: {
        Prefer: 'return=minimal',
      },
    });
    return true;
  } catch (error) {
    if (
      error instanceof ProblemJsonError &&
      error.problem.type === '/api/problems/aid/session/not-found'
    ) {
      return false;
    }
    throw error;
  }
}

function hasAlreadyAttemptedThirdPartyLogin() {
  return cache.get(CACHE_KEY, rt.Boolean);
}

function flagThirdPartyLoginAttempt() {
  return cache.set(CACHE_KEY, true, CACHE_TTL);
}
