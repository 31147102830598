import * as rt from 'runtypes';
import { merge as deepmerge } from 'ts-deepmerge';

import {
  getNamespaces,
  saveNamespace,
  updateNamespace,
  EuropaSchema,
  DEFAULT_CLIENT_ID,
} from '../clients/europa';
import store from '../store';
import allSettledPolyfill from '../polyfills/allSettledPolyfill';
export const requestStorage = async (requestedStorage: {
  [clientId: string]: Array<string>;
}) => {
  store.merge('internalState', {
    requestedStorage: deepmerge(
      store.get('internalState').requestedStorage,
      requestedStorage
    ),
  });
  const promises: Array<Promise<unknown>> = [];
  Object.keys(requestedStorage).forEach((clientId) => {
    promises.push(
      getNamespaces(clientId, requestedStorage[clientId]).then((storage) => {
        store.merge('storage', { [clientId]: storage });
      })
    );
  });

  await (Promise.allSettled || allSettledPolyfill).bind(Promise)(promises);
};

/**
 * @deprecated Use Namespace.save()
 * This is not in use by any active project anymore
 */
export const updateStorage = (storage: rt.Static<typeof EuropaSchema>) =>
  Object.keys(storage).forEach((namespace) =>
    updateNamespace(DEFAULT_CLIENT_ID, namespace, storage[namespace])
  );

/**
 * @deprecated Use Namespace.save()
 * This is not in use by any active project anymore
 */
export const saveStorage = (storage: rt.Static<typeof EuropaSchema>) =>
  Object.keys(storage).forEach((namespace) =>
    saveNamespace(DEFAULT_CLIENT_ID, namespace, storage[namespace])
  );
