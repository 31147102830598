import { getCurrentUser } from '../clients/user';
import cache from '../cache';
import { getRPCClient } from '../lib/rpc';
import { getCurrentHostname } from '../utils/url';
import log from '../utils/log';
import { UserAttributes } from '../types';
import { navigateTo } from '../utils/location';
import { removeCookie } from '../lib/cookie';

export function getLoginUrl(requestedUrl: URL | string, context = 'login_url') {
  const url = new URL(`https://${getCurrentHostname()}/aid/login`);
  url.searchParams.set(
    'requested_url',
    requestedUrl instanceof URL ? requestedUrl.href : requestedUrl
  );
  url.searchParams.set('context', context);
  return url;
}

function getLogoutUrl() {
  return new URL(`https://${getCurrentHostname()}/aid/logout`);
}

export function login(requestedUrl: URL | string, context = 'login') {
  const url = getLoginUrl(requestedUrl, context);
  navigateTo(url.href);
}

function clearCookies() {
  removeCookie('VSTOKEN');
  removeCookie('VSTOKEN2');
  removeCookie('aid.session', true);
  removeCookie('aid.jwt', true);
}

export async function logout() {
  try {
    await fetch(getLogoutUrl().href, {
      headers: {
        Accept: 'application/json',
      },
    });
    await cache.clear();
    getRPCClient().userAuthenticated(false);
  } catch (error) {
    log.error(
      'Failed to logout still cleared cookies, so this logout, on this particular site, was still successful',
      error
    );
  } finally {
    clearCookies();
  }
}

export const getUser = (): Promise<Partial<UserAttributes>> => getCurrentUser();
