import { CacheEntry, Engine } from '../types';
import getGlobalThis from '../../utils/getGlobalThis';

declare global {
  // eslint-disable-next-line no-var
  export var amediaUserCache: { [index: string]: CacheEntry };

  interface Window {
    amediaUserCache?: { [index: string]: CacheEntry };
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    interface Global {
      amediaUserCache?: { [index: string]: CacheEntry };
    }
  }
}

const getStorage = () => {
  const rootObject = getGlobalThis();

  return rootObject.amediaUserCache || (rootObject.amediaUserCache = {});
};

export const get = (key: string): Promise<CacheEntry> => {
  const data = getStorage()[key];
  return Promise.resolve(data);
};

export const set = (
  key: string,
  data: unknown,
  expires: number
): Promise<void> => {
  getStorage()[key] = { key, data, expires };
  return Promise.resolve();
};

export const remove = (key: string): Promise<void> => {
  delete getStorage()[key];
  return Promise.resolve();
};

export const clear = (): Promise<void> => {
  getGlobalThis().amediaUserCache = {};
  return Promise.resolve();
};

export default { get, set, remove, clear } satisfies Engine;
